import request from "@/utils/request";

// 接口：分页获取用户
// 地址：http://${BASE_URL}/user_service/sysUser/list?pageNum={pageNum}&pageSize={pageSize}&keyword={keyword}
export function sysUserList(data) {
  return request({
    url: "/user-service/sysUser/list",
    method: "post",
    data
  });
}
// 接口：根据父级部门ID获取子部门信息
// 地址：http://${BASE_URL}/user_service/sysDept/{id}/list
export function sysDeptList(id) {
  return request({
    url: `/user-service/sysDept/list?id=${id}`,
    method: "get",
  });
}
// 接口：分页获取角色列表
// 地址：http://${BASE_URL}/user-service/sysRoleList
export function sysRoleList(data) {
  return request({
    url: "/user-service/sysRoleList",
    method: "post",
    data
  });
}
//获取菜单树状图
export function sysMenuTree() {
  return request({
    url: `/user-service/sysMenu/tree`,
    method: "get",
  });
}
// 接口：更新角色
// 地址：http://${BASE_URL}/user-service/sysRole
export function sysRolePut (data) {
  return request({
      url: "/user-service/sysRole",
      method: 'put',
      data
  })
}
// 接口：删除角色
// 地址：http://${BASE_URL}/user-service/sysRole/{id}
export function sysRoleDel (id) {
  return request({
      url: `/user-service/sysRole/${id}`,
      method: 'delete'
  })
}
// 接口：创建角色
// 地址：http://${BASE_URL}/user-service/sysRole
export function sysRolePost(data) {
  return request({
    url: "/user-service/sysRole",
    method: "post",
    data
  });
}
// 接口：获取角色详情
// 地址：http://${BASE_URL}/user-service/sysRoleDetail?id=#{id}
export function sysRoleDetail(id) {
  return request({
    url: `/user-service/sysRoleDetail?id=${id}`,
    method: "get",
  });
}